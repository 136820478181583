import Image from 'next/image';
import React, {
  useEffect,
  useMemo,
  useState,
  ChangeEvent,
  ComponentProps,
} from 'react';

import { COUNTRY_CODES } from '../../../constants/countryCodes.constants';
import { EInputVariant, Input } from '../Input';
import {
  ESelectBackgroundVariant,
  ESelectVariant,
  Option,
  Select,
} from '../Select';

const DEFAULT_CODE_INDEX = 234;

export type TPhoneInputProps = {
  onChange: (value: string) => void;
  required?: boolean;
  countryLabel: string;
} & Partial<ComponentProps<typeof Input>>;

const PhoneInput: React.FC<TPhoneInputProps> = props => {
  const {
    placeholder,
    countryLabel,
    value,
    onChange,
    variant,
    ...restInputProps
  } = props;

  const [currentCodeIndex, setCurrentCodeIndex] = useState<number | null>(
    DEFAULT_CODE_INDEX,
  );
  const [currentPhone, setCurrentPhone] = useState(value || '');

  const currentFlag = useMemo(
    () =>
      typeof currentCodeIndex === 'number' ? (
        <Image
          width={14}
          height={14}
          src={`/icons/flags/${COUNTRY_CODES[currentCodeIndex].iso2}.svg`}
          className='border-[0.9px] border-surface-200 object-contain object-center'
          alt={COUNTRY_CODES[currentCodeIndex].iso2}
        />
      ) : null,
    [currentCodeIndex],
  );

  const Options = useMemo(
    () =>
      COUNTRY_CODES.map((c, i) => (
        <Option key={i} label={c.dialCode}>
          <svg
            height='14'
            width='14'
            className='h-auto w-3.5 object-contain object-center'
          >
            <use xlinkHref={`/icons/flags/flags-sprite.svg#${c.iso2}`}></use>
          </svg>
          <span className='whitespace-nowrap'>+{c.dialCode}</span>
        </Option>
      )),
    [],
  );

  const valueOptions = useMemo(
    () =>
      COUNTRY_CODES.map(c => ({ label: `+${c.dialCode}`, value: c.dialCode })),
    [],
  );

  useEffect(() => {
    if (!value && currentPhone) {
      // support reset
      setCurrentCodeIndex(DEFAULT_CODE_INDEX);
      setCurrentPhone('');
    }
  }, [value, currentPhone]);

  const onChangeCurrentCodeIndex = (newCodeIndex: number | null): void => {
    setCurrentCodeIndex(newCodeIndex);
    onChange(
      newCodeIndex && currentPhone
        ? `${COUNTRY_CODES[newCodeIndex].dialCode}${currentPhone}`
        : '',
    );
  };

  const onChangePhoneNumber = (e: ChangeEvent<HTMLInputElement>): void => {
    const newPhoneNumberValue = (e.target.value || '').replace(/[^0-9]/g, '');

    setCurrentPhone(newPhoneNumberValue);
    onChange(
      currentCodeIndex && newPhoneNumberValue
        ? `${COUNTRY_CODES[currentCodeIndex].dialCode}${newPhoneNumberValue}`
        : '',
    );
  };

  const selectBackgroundVariant =
    variant === EInputVariant.Primary
      ? ESelectBackgroundVariant.Primary
      : ESelectBackgroundVariant.Secondary;

  return (
    <div className='flex gap-2'>
      <div className='relative box-border w-[7.75rem]'>
        <Select
          variant={ESelectVariant.Input}
          backgroundVariant={selectBackgroundVariant}
          label={countryLabel}
          value={currentCodeIndex}
          onChange={onChangeCurrentCodeIndex}
          options={valueOptions}
          icon={currentFlag}
        >
          {Options}
        </Select>
      </div>
      <div className='flex-grow'>
        <Input
          {...restInputProps}
          fullWidth
          variant={variant || EInputVariant.Secondary}
          placeholder={placeholder}
          value={currentPhone}
          onChange={onChangePhoneNumber}
        />
      </div>
    </div>
  );
};

export default PhoneInput;
